.cluster_peoples,
.cluster_resource,
.cluster_decade,
.cluster {
  > header {
    @apply bg-solid-black text-white flex gap-[40px];
    @apply bg-[url("/images/shared/History-of-the-Mobile-Phone_Collection-Header-Bg_810x1080.jpg")];
    @apply lg:bg-[url("/images/shared/History-of-the-Mobile-Phone_Collection-Header-Bg_1920x1080.jpg")];
    @apply bg-origin-border bg-no-repeat bg-bottom bg-cover;
    @apply w-screen h-screen relative;

    width: 100vw;
    min-height: 100vh;
    height: 100%;

    a {
      margin-right: 5px;
    }

    article { @apply !w-full lg:!w-1/2 lg:pl-[40px] }
  }
  main {
    @apply max-w-[1550px] mx-auto my-[80px];
    @apply grid grid-cols-1 justify-items-center gap-[30px];

    @media (min-width: 850px) {
      & {
        @apply grid-cols-2;
      }
    }
    @media (min-width: 1366px) {
      & {
        @apply grid-cols-3 !gap-[30px] max-w-[1260px] !px-0  mt-[175px];
      }
    }
  }
  header article {
    @apply w-full md:w-1/2 md:ml-5;
    @apply flex flex-col gap-[30px];

    h1 {
      @apply heading-xxl;
    }
    h2 {
      @apply heading-sm-alt text-white tracking-[.01em];
    }
    p {
      @apply meta-normal text-medium-grey;
    }
    @media (min-width: 769px) {
      p {
        @apply body;
      }
    }
  }
  header aside {
    @apply w-full md:w-1/2 relative;

    .hero-image {
      @apply w-[76.38888888888889%];
      @apply rotate-3 !important;
    }
  }
  .card {
    @apply bg-very-black max-w-[750px] flex;
    @apply lg:odd:justify-self-end lg:even:justify-self-start;
    @apply xl:odd:justify-self-center xl:even:justify-self-center;

    @media (min-width: 1366px) {
      &:nth-child(3n+2) {
        @apply md:-mt-[75px];
      }
    }

    header figure.media {
      overflow: hidden;
      width: 100%;
      max-width: 100%;
    }

    header figure.media img {
      max-width: 100%;
      width: 100%;
      height: auto;
      object-fit: unset;
      object-fit: contain;
    }
    header figure.media figcaption.visually-hidden {
      visibility: hidden;
      height: 0;
    }
  }

  @media screen and (max-width: 849px) {
    main.revised-cluster-grid {
        max-width: 809px;
        grid-template-columns: 100%;
    }
    main.revised-cluster-grid .card {
        max-width: 100%;
        width: 100%;
        height: auto;
    }
    main.revised-cluster-grid .card a {
        display: block;
        max-width: 100%;
        width: 100%;
    }
    main.revised-cluster-grid .card article {
        height: auto;
        max-height: 100%;
        width: calc(100% - 100px);
        max-width: calc(100% - 100px);
        margin: auto auto;
    }
    main.revised-cluster-grid .card article header {
        width: 100%;
    }
    main.revised-cluster-grid .card header figure.media img {
        max-width: 100%;
        width: 100%;
        height: auto;
        max-height: 379px;
        object-fit: cover;
    }
  }

  @media screen and (min-width: 849px) {
    main.revised-cluster-grid .card article header {
        width: 100%;
    }
    main.revised-cluster-grid .card header figure.media {
        width: 100%;
        max-width: 100%;
    }
    main.revised-cluster-grid .card header figure.media img {
        max-width: 100%;
        width: 100%;
        height: auto;
        max-height: 175px;
        object-fit: cover;
    }
  }
}

@media screen and (max-width: 849px) {
  body.cluster,
  body.cluster_decade,
  body.cluster_peoples,
  body.cluster_resource {
    &.revised-cluster-styles main.revised-cluster-grid .card.journal {
      width: 100%;
      max-width: 100%;
    }
    &.revised-cluster-styles main.revised-cluster-grid .card.event article {
      max-width: calc(100% - 0px);
      padding: 32px;
      width: 100%;
    }
    &.revised-cluster-styles main.revised-cluster-grid .card.journal article {
      max-width: calc(100% - 0px);
      padding: 32px;
      width: 100%;
      height: 100%;
    }
    &.revised-cluster-styles main.revised-cluster-grid .card.prompt article {
      max-width: calc(100% - 0px);
      padding: 32px;
      width: 100%;
    }
    &.revised-cluster-styles main.revised-cluster-grid .card.cta article {
      max-width: calc(100% - 0px);
      padding: 32px;
      width: 100%;
    }
    & main.revised-cluster-grid .card header figure.media img {
      max-height: unset;
    }
  }
}

@media screen and (min-width: 1260px) and (max-width: 1300px) {
  .cluster_peoples main, .cluster_resource main, .cluster main {
      /* max-width: 1260px; */
      max-width: calc((1260/1300)* 100%);
  }
}
.hero-image {
  @apply bg-cover bg-center mx-auto lg:mr-0;
  width: var(--cluster-image-size);
  height: var(--cluster-image-size);
}

.media-credit:active,
.media-credit:focus,
.media-credit:focus-visible  {
  outline-width: 0.15rem;
  border-radius: 0px;
  outline-style: dotted;
  outline-color: #2ef853;
}

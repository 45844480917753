@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');

:root {
  font-family: 'Montserrat', sans-serif;
}

.heading-xxxl,
.heading-xxl,
.heading-xl,
.heading-lg,
.heading-md,
.heading-sm,
.heading-sm-alt,
.heading-xs,
.heading-xs-alt,
.heading-xxs,
.body,
.base,
.meta {
  font-size: clamp(
    var(--font-size-min),
    var(--font-size-val),
    var(--font-size-max)
  );
  line-height: clamp(var(--line-height-min), var(--line-height-val), var(--line-height-max));
}
.heading-xxxl {
  @apply capitalize tracking-[-0.02em];
  font-weight: 700;
  --font-size-min: 72px;
  --font-size-val: 10.45vw;
  --font-size-max: 180px;

  --line-height-min: 72px;
  --line-height-val: 10vw;
  --line-height-max: 170px;
}
.heading-xxl {
  @apply capitalize tracking-tight;
  font-weight: 800;
  --font-size-min: 44px;
  --font-size-val: 5.25vw;
  --font-size-max: 90px;

  --line-height-min: 48px;
  --line-height-val: 5.5vw;
  --line-height-max: 94px;
}
.heading-xl {
  --font-size-min: 36px;
  --font-size-val: 4.2vw;
  --font-size-max: 72px;

  --line-height-min: 40px;
  --line-height-val: 4.55vw;
  --line-height-max: 78px;
}
.heading-lg {
  @apply uppercase;
  font-weight: 800;
  --font-size-min: 36px;
  --font-size-val: 4.25vw;
  --font-size-max: 56px;

  --line-height-min: 30px;
  --line-height-val: 4vw;
  --line-height-max: 50px;
}
.heading-md {
  --font-size-min: 36px;
  --font-size-val: 4vw;
  --font-size-max: 40px;

  --line-height-min: 40px;
  --line-height-val: 2.75vw;
  --line-height-max: 46px;
}
.heading-sm {
  font-weight: 800;
  letter-spacing: 0.1em;
  --font-size-min: 36px;
  --font-size-val: 2.25vw;
  --font-size-max: 36px;

  --line-height-min: 36px;
  --line-height-val: 2.35vw;
  --line-height-max: 40px;
}
.heading-sm-alt {
  --font-size-min: 36px;
  --font-size-val: 2.25vw;
  --font-size-max: 36px;

  --line-height-min: 36px;
  --line-height-val: 2.8vw;
  --line-height-max: 40px;
}
.heading-xs {
  font-weight: 600;
  letter-spacing: 0.1em;
  --font-size-min: 24px;
  --font-size-val: 1.4vw;
  --font-size-max: 24px;

  --line-height-min: 28px;
  --line-height-val: 1.7vw;
  --line-height-max: 28px;
}
.heading-xs-alt {
  --font-size-min: 24px;
  --font-size-val: 1.4vw;
  --font-size-max: 24px;

  --line-height-min: 28px;
  --line-height-val: 1.7vw;
  --line-height-max: 28px;
}
.heading-xxs {
  font-weight: 700;
  text-transform: capitalize;
  --font-size-min: 20px;
  --font-size-val: 1.25vw;
  --font-size-max: 20px;

  --line-height-min: 24px;
  --line-height-val: 1.4vw;
  --line-height-max: 24px;
}
.body {
  --font-size-min: 17px;
  --font-size-val: 1vw;
  --font-size-max: 17px;
  line-height: 26px;
}
.base {
  --font-size-min: 16px;
  --font-size-val: 0.95vw;
  --font-size-max: 16px;

  --line-height-min: 24px;
  --line-height-val: 1.4vw;
  --line-height-max: 24px;
}
.meta {
  font-weight: 600;
  text-transform: lowercase;
  --font-size-min: 16px;
  --font-size-val: 2vw;
  --font-size-max: 16px;

  --line-height-min: 20px;
  --line-height-val: 1.25vw;
  --line-height-max: 20px;
}
.meta-normal {
  --font-size-min: 16px;
  --font-size-val: 2vw;
  --font-size-max: 16px;

  --line-height-min: 20px;
  --line-height-val: 1.25vw;
  --line-height-max: 20px;
}

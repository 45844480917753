:root {
  --carousel-padding-left:
      clamp(
      var(--carousel-padding-left-min),
      var(--carousel-padding-left-val),
      var(--carousel-padding-left-max)
    );
  --carousel-padding-left-min: 30px;
  --carousel-padding-left-val: calc(1vw * var(--carousel-padding-multiplier));
  --carousel-padding-left-max: 330px;

  --carousel-padding-multiplier: 0.1;
  @media (min-width: 400px) {
    --carousel-padding-multiplier: 8;
  }
  @media (min-width: 800px) {
    --carousel-padding-multiplier: 15;
  }
  @media (min-width: 1280px) {
    --carousel-padding-multiplier: 10;
  }
}

[data-controller="carousel"] {
  @apply bg-white pt-[80px] md:pt-[100px] pb-[80px] md:pb-[95px] text-medium-black;
  padding-left: var(--carousel-padding-left) !important;

  h3 {
    @apply heading-xs mb-[30px] md:mb-[15px] w-fit;
    @apply text-medium-black font-semibold tracking-[.01em];
  }

  nav {
    @apply text-fuchsia heading-xxs gap-x-5;
    flex-grow: 1;
    font-weight: normal !important;
    margin-right: 2rem !important;

    button:not(.swiper-button-disabled):hover {
      text-decoration: underline;
      text-underline-offset: 4px;
      text-decoration-thickness: 2px;
    }
    @media (max-width: 1023px) {
      &.tabletAndAbove {
        display: none;
      }
      &.mobile {
        display: flex;
        margin-top: 1rem;
      }
    }
    @media (min-width: 1024px) {
      &.tabletAndAbove {
        display: flex;
        justify-content: flex-end;
        margin-top: -1rem;
      }
      &.mobile {
        display: none;
      }
    }

    [data-carousel-target="prev"] {
      &::before {
        content: "";
        background-color: currentColor;
        display: inline-block;
        mask: url('/images/shared/arrow_back_icon.svg') no-repeat 50% 50%;
        mask-size: contain;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 4px;
        vertical-align: sub;
      }
    }
    [data-carousel-target="next"] {
      &::after {
        content: "";
        background-color: currentColor;
        display: inline-block;
        mask: url('/images/shared/arrow_forward_icon.svg') no-repeat 50% 50%;
        mask-size: contain;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-left: 4px;
        vertical-align: sub;
      }
    }
  }
}
.carousel {
  @apply w-full flex-col gap-12;

  .poster {
    @apply w-[300px];

    .cover {
      @apply bg-cover bg-center h-[430px] w-[300px] mb-[26px];
      border-radius: 5% 5% 5% 0;
    }
    h3 {
      @apply heading-xxs text-medium-black mb-[16px];
      &:hover {
        @apply underline decoration-medium-black;
      }
    }
    p { @apply text-base text-dark-grey }
  }
}
.swiper {
  margin-right: 0px !important;

  & .swiper-slide:last-of-type {
    margin-right: 30px !important;
  }
}
.swiper-wrapper {
  @apply h-[320px] md:h-[512px] flex;
}
.swiper-button-disabled {
  @apply text-dark-grey
}

.carousel-link:active,
.carousel-link:focus-visible,
.carousel-link:focus {
  display: block;
  outline-width: 0.15rem;
  border-radius: 0px;
  outline-style: dotted;
  outline-color: #1fb23a;
}

.carousel-img-link:active,
.carousel-img-link:focus-visible
.carousel-img-link:focus {
  display: block;
  outline-width: 0.17rem;
  border-radius: 0px;
  outline-style: dotted;
  outline-color: #1fb23a;
}

[data-carousel-target="next"]:active,
[data-carousel-target="next"]:focus-visible,
[data-carousel-target="next"]:focus,
[data-carousel-target="prev"]:active,
[data-carousel-target="prev"]:focus-visible,
[data-carousel-target="prev"]:focus {
  outline-width: 0.15rem;
  border-radius: 1px;
  outline-style: dotted;
  outline-color: #1fb23a;
}

@media screen and (max-width: 768px) {
  body.cta,
  body.entry {
    & .carousel .card.cta article header,
    & .carousel .card.event article header {
      width: 100% !important;
      max-width: 100% !important;

      & img {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .card.swiper-slide.journal.explore {
    width: 350px !important;
    max-width: 100% !important;
  }
}
.fade-in {
  animation: fadein var(--transition-duration);
  -moz-animation: fadein --transition-duration; /* Firefox */
  -webkit-animation: fadein var(--transition-duration); /* Safari and Chrome */
  -o-animation: fadein var(--transition-duration); /* Opera */
}
@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-moz-keyframes fadein { /* Firefox */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-o-keyframes fadein { /* Opera */
  from {
      opacity:0;
  }
  to {
      opacity: 1;
  }
}

.button {
  @apply body text-center w-fit;
  @apply animate_color;
  border-width: 1.5px;
  border-radius: 7px;
  display: block;
  font-weight: normal;
  letter-spacing: .01em;
  text-transform: uppercase;

  &:hover {
    @apply animate_opacity;
  }
}
.button.primary {
  @apply text-black bg-lime-green hover:bg-lime-green/[0.7];
  @apply hover:underline active:no-underline;
  @apply min-h-[50px] min-w-[190px];
  font-weight: 500;
  @apply p-[15px];
  border: none;
}
.button.secondary {
  @apply text-medium-grey border-medium-grey;
  @apply hover:text-lime-green/100 hover:border-lime-green/70 hover:underline active:no-underline min-h-[50px] min-w-[190px];
  @apply p-[15px];
}
.button.tertiary {
  @apply text-lime-green;
  @apply border-lime-green hover:border-lime-green/70;
  @apply bg-transparent hover:bg-lime-green/[0.12];
  @apply hover:underline active:no-underline;
  @apply min-h-[50px] md:min-h-[60px] min-w-[220px] md:min-w-[350px];
  @apply px-[25px] py-[17px] md:py-[15px];
  @apply md:text-[24px];
}

.button.secondary:active,
.button.secondary:focus-visible,
.button.secondary:focus {
  outline-width: 0.15rem;
  border-radius: 0px;
  outline-style: dotted;
  outline-color: #2ef853;
}

.button.tertiary:active,
.button.tertiary:focus-visible,
.button.tertiary:focus {
  outline-width: 0.15rem;
  border-radius: 0px;
  outline-style: dotted;
  outline-color: #2ef853;
}
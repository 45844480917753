:root {
  --nav-height: 70px;
}
@media (min-width: 769px) {
  :root {
    --nav-height: 110px;
  }
}

nav[data-controller="open-close"] {
  height: var(--nav-height);

  .logo {
    margin-right: 26px;
    width: 37px;
    height: 50px;
  }
  @media (max-width: 768px) {
    & .logo {
      width: 29px;
      height: 40px;
    }
  }
}
body.no--scroll {
  @apply bg-solid-black text-white;
  overflow: hidden;
}
nav #main-site-nav-wrapper {
  @apply w-screen h-screen bg-very-black text-white;
  @apply z-20 fixed left-0 right-0;
  @apply px-[25px] md:px-[70px] py-12 gap-y-10 gap-x-6;
  @apply overflow-scroll overscroll-contain hidden;
  top: calc(var(--nav-height) - 10px);
}
nav #main-site-nav-inner {
  @apply grid grid-cols-1 lg:grid-cols-2;
  @apply gap-y-6 gap-x-[200px];
  @apply pt-[35px] mb-[50px] md:mb-[100px] mx-auto;
  max-width: 1260px;
}
nav.nav--open {
  @apply bg-solid-black;
  overflow: hidden;
}
nav.nav--closed,
nav.nav--closing {
  overflow: hidden;
}
nav.nav--open #main-site-nav-wrapper {
  @apply animate-fadein !block;
  background-color: #050505;
}
nav.nav--closing #main-site-nav-wrapper {
  @apply animate-fadeout !block;
}
nav.nav--closed #main-site-nav-wrapper {
  @apply opacity-0 hidden;
}

nav.nav--open #menu-icon > #middle {
  opacity: 0;
}
nav.nav--open #menu-icon > #bottom {
  transform: rotate(-45deg) translate(-15px);
}
nav.nav--open #menu-icon > #top {
  transform: rotate(45deg) translate(4px, -12px);
}
nav #menu-icon path {
  transition: all 0.75s;
}

@media (prefers-reduced-motion: reduce) {
  nav.nav--open #menu-icon > #middle,
  nav.nav--open #menu-icon > #bottom,
  nav.nav--open #menu-icon > #top,
  nav #menu-icon path {
    transition: none;
  }
}

.nav-logo:active,
.nav-logo:focus-visible,
.nav-logo:focus {
  outline-width: 0.15rem;
  border-radius: 0px;
  outline-style: dotted;
  outline-color: #2ef853;
}

button.nav-menu-button:active,
button.nav-menu-button:focus-visible,
button.nav-menu-button:focus
{
  outline-width: 0.15rem;
  border-radius: 0px;
  outline-style: dotted;
  outline-color: #2ef853;
  }

.nav-participate:active,
.nav-participate:focus-visible,
.nav-participate:focus,
.nav-link:active,
.nav-link:focus-visible,
.nav-link:focus {
  outline-width: 0.15rem;
  border-radius: 0px;
  outline-style: dotted;
  outline-color: #2ef853;
}

.nav > ul > li {
  a:active,
  a:focus,
  a:focus-visible  {
    outline-width: 0.15rem;
    border-radius: 0px;
    outline-style: dotted;
    outline-color: #2ef853;
  }
}
.card {
  @media (max-width: 768px) {
    & {
      max-width: 400px;
    }
  }
  @media (min-width: 769px) {
    & {
      max-width: 400px;
      column-gap: 30px !important;
    }
  }
  @apply h-[512px] min-h-[512px] bg-very-black text-base text-off-white;

  article {
    @apply w-[300px] h-[412px] md:max-h-[412px] m-[50px] flex flex-col;
    text-overflow: ellipsis;

    header {
      @apply flex flex-col-reverse w-[300px] bg-inherit
    }

    @media (max-width: 768px) {
      width: 100% !important;
      margin: 0;
      height: 100% !important;
      padding: 32px;
    }

  }
  img {
    @apply mb-[20px] w-[300px] h-fit max-w-[300px] max-h-[175px];
    @apply object-cover object-top;
    @media (max-width: 400px) {
      width: 100%;
    }
  }
  time {
    @apply text-medium-grey mb-[20px];
  }
  p {
    @apply w-full overflow-hidden;
  }
  h2 {
    @apply heading-xs mb-[20px];
  }
  &.event {
    h2 {
      @apply text-white font-medium tracking-[0.01em];
      &:hover {
        @apply underline decoration-white;
      }
    }
  }
  &.cta {
    h2 {
      @apply text-blue tracking-[0.01em];
      &:hover {
        @apply underline decoration-blue
      }
      &:focus, &:active, &:focus-visible {
        outline-width: 0.09375rem;
        border-radius: 1px;
        outline-style: dotted;
        outline-color: #2ef853;
      }
    }
  }
  &.journal {
    @apply text-center;
    h2 {
      @apply heading-sm text-fuchsia mb-5;
      @apply tracking-[0.01em] font-extrabold;
      margin-top: auto;
      &:hover {
        @apply underline decoration-fuchsia;
      }
      &:focus, &:active, &:focus-visible {
        outline-width: 0.09375rem;
        border-radius: 1px;
        outline-style: dotted;
        outline-color: #2ef853;
      }
    }
    p {
      @apply base text-off-white;
      margin-bottom: auto;
    }
  }
  &.prompt {
    h2 {
      @apply heading-sm text-lime-green;
      @apply tracking-[0.01em] font-extrabold;
      margin-bottom: 36px;
    }
    article {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
    a:hover {
      @apply text-lime-green border-lime-green;
    }
  }
  &.swiper-slide {
    @apply w-[280px] !important;
    @apply h-[320px] !important;
    @apply md:w-[400px] !important;
    @apply md:h-[512px] !important;
  }
  @media (max-width: 400px) {
    width: 100% !important;
    min-width: 100% !important;
  }
}

.explore-card-link:active,
.explore-card-link:focus,
.explore-card-link:focus-visible {
  display: block;
  outline-width: 0.15rem;
  border-radius: 0px;
  outline-style: dotted;
  outline-color: #2ef853;
}

.card-event-link:active,
.card-event-link:focus,
.card-event-link:focus-visible {
  outline-width: 0.15rem;
  border-radius: 0px;
  outline-style: dotted;
  outline-color: #2ef853;
}

.card-journal-link:active,
.card-journal-link:focus,
.card-journal-link:focus-visible,
.cta-card-link:active,
.cta-card-link:focus,
.cta-card-link:focus-visible {
  outline-width: 0.15rem;
  border-radius: 0px;
  outline-style: dotted;
  outline-color: #2ef853;
}
:root {
  --cluster-image-adjustment: 0.09;
  --fence-width-desktop: 884px;
  --width-desktop: 1024px;
}
.default:not(.no--scroll) {
  & > header {
    @apply text-white bg-solid-black items-center;
    @apply bg-[url("/images/shared/History-of-the-Mobile-Phone_Homepage_Header-Bg-mobile.jpg")];
    @apply lg:bg-[url("/images/shared/History-of-the-Mobile-Phone_Homepage_Header-Bg-fullscreen.jpg")];
    @apply bg-origin-border bg-no-repeat bg-bottom bg-cover;

    display: flex;
    height: 100vh;
    position: relative;
    width: 100%;

    @media (max-width: 1024px) {
      h1 {
        font-size: 50px;
        line-height: 50px;
        font-weight: bold;
        margin-top: -35px;
      }
    }

    @media (min-width: 1025px) {
      h1 {
        @apply heading-xxl;
        margin-top: -55px;
      }
    }
  }

  .entry-link:hover {
    @apply underline decoration-medium-black;
  }

  .journal-link:hover {
    @apply underline decoration-fuchsia;
  }

  .introduction {
    @apply w-[80%] lg:w-[62%] lg:max-w-[960px];
    @apply flex flex-col body;
    @apply mx-auto mt-[75px] md:mt-[95px] -mb-[10px];

    p { @apply mb-6; }
    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
  article.highlight {
    @apply flex flex-col-reverse items-center;
    @apply w-[73%] lg:w-[55%] max-w-[860px] mx-auto mt-[120px] mb-[64px];


    h2 {
      @apply heading-md text-dark-grey text-center w-full;
    }
    img {
      @apply w-[65px] h-[65px] mb-[14px];
    }
  }
  .cluster-heading {
    @apply h-fit justify-center mb-[80px] lg:mb-[200px];
    display: flex;
    width: 100%;

    article {
      position: relative;
      text-align: left;

      width: var(--cluster-image-size);
      height: var(--cluster-image-size);

      .wrapper-for-centering {
        margin-left: calc(var(--cluster-image-size) * var(--cluster-image-adjustment));
      }
      .cluster-title {
        @apply heading-lg uppercase text-medium-black tracking-tight;

        position: absolute;
        top: 0;
        left: 0;
        transform:
          translateX(calc(var(--cluster-image-size) * calc(var(--cluster-image-adjustment) * -1)))
          translateY(calc(var(--cluster-image-size)*1.05))
          rotate(270deg);
        transform-origin: left top;
        z-index: 2;

        &.glow {
          stroke-width: 12px;
          -webkit-text-stroke-width: 12px;
          -webkit-text-stroke-color: #fff;
          -webkit-text-fill-color: #1a1a1a;
          z-index: 1;
        }
      }
    }
  }
  figure {
    .event-image {
      @apply bg-cover bg-center mt-[10px];
      @apply md:w-[325px] md:h-[325px];
      width: var(--event-image-square-size);
      height: var(--event-image-square-size);
    }
    .visually-hidden {
      position: absolute;
      width: 1px;
      height: 1px;
      margin: -1px;
      padding: 0;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }
  }
  .cluster-link {
    @apply p-1 px-4 border border-solid-black w-fit lowercase;
    display: block;
    &:hover {
      @apply underline decoration-medium-black;
    }
  }
  ul.cluster-grid {
    display: grid;

    @media (min-width: 1025px) {
      column-gap: calc((100 / 884) * 50%);
      grid-template-columns: repeat(2,minmax(47%,725px));
      grid-template-rows: auto;

      ul > li .wrapper {
        @apply flex flex-row-reverse;
      }

      & > li:nth-child(1) { /* Highlight */
        grid-column: 1 / -1;
      }
      & > li:nth-child(4) { /* Journal */
        grid-column: 1 / -1;
      }
      & > li:nth-child(7) { /* Prompt */
        grid-column: 1 / -1;
      }

      & > li:nth-child(3), & > li:nth-child(5) { /* Entries with top margin*/
        margin-top: calc((220 / 884) * 100%);
      }
    }

    @media (min-width: 1365px) {
      & > li:nth-child(2) {
        @apply h-[485px];
      }

      & > li:nth-child(3) {
        @apply mt-[150px];
      }

      & > li:nth-child(5) {
        @apply !mt-[160px] flex items-end;
      }
    }

    @media (min-width: 1025px) and (max-width: 1365px) {
      & > li:nth-child(3) {
        @apply mt-[130px];
      }

      & > li:nth-child(5) {
        @apply mt-[140px];
      }
    }

  }
  ul > li.home {
    @apply w-full;

    @media (max-width: 1025px) {
      & .quote-wrapper {
        h2 {
          @apply !text-left;
        }
      }
    }

    @media (max-width: 1024px) {
      .thumbnail-desktop {
        display: none;
      }

      & .wrapper {
        @apply mt-[100px];
      }

      &:nth-child(3) .wrapper {
        @apply mt-0;
      }

      .source-location {
        text-align: left;
      }
      & .wrapper.participate article {
        @apply flex flex-col items-end text-right;
      }

      &:nth-child(even) {
        @apply items-start;

        header, p, a {
          text-align: left;
        }

        .cluster-link {
          @apply self-start;
          order: 1; /* 1 = last */
        }
        & .wrapper {
          @apply items-start;
        }
      }
      &:nth-child(odd) {
        @apply items-end;
        header, p, a {
          text-align: right;
        }

        .cluster-link {
          @apply self-end;
        }
        .thumbnail {
          justify-content: end;
        }
        & .wrapper {
          @apply items-end ;
        }
      }
    }
    @media (min-width: 1366px) and (max-width: 1690px) {
      & .wrapper {
        flex-wrap: unset !important;
      }
    }
    @media (min-width: 1025px) {
      .thumbnail {
        display: none;
      }
      .source-location {
        text-align: center;
      }
      & .wrapper {
        flex-wrap: wrap;
      }
      & .wrapper.participate {
        width: 50%;
        max-width: 500px;
        margin-left: 53%;
      }
      & .wrapper.journal {
        @apply w-[47%];

        & .quote-wrapper  {
          @apply mx-auto max-w-[500px];
          h3 {
            @apply text-center;
          }
        }
      }

      & .wrapper.participate,
      & .wrapper.journal {
        @apply my-[60px];
      }
      & .wrapper.event article, .wrapper.resource article {
        @apply mt-[45px];
      }
    }

    @media (max-width: 1365px) {
      & .wrapper.event article {
        @apply mt-[26px];
      }
    }

    & .wrapper {
      @apply w-full;
      @apply flex;
      gap: clamp(26px, 3.5vw, 50px);
    }
    header {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}

@media (min-width: 1366px) {
  & .wrapper {
    flex-wrap: unset !important;
  }
}

@media (min-width: 1366px) and (max-width: 1690px) {
  main.container {
    max-width: calc((1226/1366)*100%);
  }
  .default ul.cluster-grid {
    -moz-column-gap: calc((150 / 1266) * 50%);
    column-gap: calc((150 / 1266) * 50%);
    grid-template-columns: repeat(2,minmax(47%,725px));
    grid-template-rows: auto;
  }
}
@media (min-width: 1691px) {

    main.container {
      max-width: 1690px;
    }
    .default ul.cluster-grid {
      column-gap: calc((100 / 1550) * 100%);
      grid-template-columns: repeat(2,minmax(46%,725px));
      grid-template-rows: auto;
    }
  }


  @media (min-width: 1025px) and (max-width: 1365px) {
    li > .wrapper.event, .wrapper.resource {
      gap: 0 !important;
    }
  }

.landing-page-link:active,
.landing-page-link:focus-visible,
.landing-page-link:focus
 {
  outline-width: 0.15rem;
  border-radius: 0px;
  outline-style: dotted;
  outline-color: #1fb23a;
}
